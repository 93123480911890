import { Modal, notification, message } from "antd";

const notifyError = (error: any) => {
  notification.destroy();
  return notification.error({
    message: error?.response?.data?.title || "Something went wrong!",
    description: error?.response?.data?.message || "",
  });
};

const notifyErrorMessage = (error: any) => {
  message.destroy();
  return message.error(
    error?.response?.data?.message || "Something went wrong!"
  );
};

export const handleError = (error: any) => {
  if (error?.response?.status === 401 || error?.response?.status === 404) {
    return;
  }

  // 440 status code is received if refresh token is invalid. Time to force user to login again
  if (error?.response?.status === 440) {
    return Modal.confirm({
      title: "Session Expired!",
      content: "Do you want to go to login page?",
      okText: "Okay",
      cancelText: "Cancel",
      onOk: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      },
    });
  }

  notifyError(error);
};

export const handleErrorMessage = (error: any) => {
  notifyErrorMessage(error);
};

export default handleError;
