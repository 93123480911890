const queryKeys = {
  auth: {
    user: "authUser",
    notifications: "authNotifications",
    business: "authBusiness",
  },
  admin: {
    dashboard: "adminDashboard",
    sliders: "adminSliders",
    categories: "adminCategories",
    brands: "adminBrands",
    brandsForSelect: "adminBrandsForSelect",
    colors: "adminColors",
    deliveryAddresses: "adminDeliveryAddresses",
    vouchers: "adminVouchers",
    products: "adminProducts",
    product: "adminProduct",
    productFilters: "adminProductFilters",
    reviews: "adminReviews",
    review: "adminReview",
    orders: "adminOrders",
    order: "adminOrder",
    guest: {
      orders: "guestOrders",
      order: "guestOrder",
    },
    users: {
      users: "adminUsersUsers",
      influencers: "adminUsersInfluencers",
      influencerApplications: "adminUsersInfluencerApplications",
      admins: "adminUsersAdmins",
    },
    wholesale: {
      user: "adminWholesaleUser",
      users: {
        parlor: "adminWholeSaleParlor",
        parlors: "adminWholeSaleParlors",
        shop: "adminWholeSaleShop",
        shops: "adminWholeSaleShops",
        distributor: "adminWholeSaleDistributor",
        distributors: "adminWholeSaleDistributors",
      },
      image: "adminImageUpload",
      product: "adminWholesaleproduct",
      products: "adminWholesaleproducts",
      orders: "adminWholesaleOrders",
      order: "adminWholesaleOrder",
      appointment: "adminAppointment",
      appointments: "adminAppointments",
    },
    misc: {
      siteSetting: "adminSiteSetting",
      contactMessages: "adminContactMessages",
      subscribers: "adminSubscribers",
      pages: "adminPages",
      page: "adminPage",
      faqs: "adminFaqs",
      emailTemplates: "adminEmailTemplates",
      emailTemplate: "adminEmailTemplate",
    },
    aboutUs: {
      teams: "adminTeams",
      testimonials: "adminTestimonials",
    },
    blogs: "adminBlogs",
    blog: "adminBlog",
    influencerLinks: {
      influencersLinksEarning: "adminInfluencersLinksEarning",
      influencerLinksEarning: "adminInfluencerLinksEarning",
    },
    influencerPayments: "adminInfluencerPayments",
    influencerPayment: "adminInfluencerPayment",
    skinReports: "skinReports",
    skinReport: "skinReport",
  },
  shop: {
    sliders: "shopSliders",
    categories: {
      categories: "shopCategories",
      topLevelCategories: "shopTopLevelCategories",
      productsByCategory: "shopProductsByCategory",
      productsByCategoryMeta: "productsByCategoryMeta",
    },
    brands: {
      brands: "shopBrands",
      productsByBrand: "shopProductsByBrand",
    },
    colors: "shopColors",
    productBySlug: "shopProductBySlug",
    productReviews: "shopProductReviews",
    searchedProducts: "shopSearchedProducts",
    recommendationProducts: "shopRecommendationProducts",
    deals: "shopDeals",
    dealsMeta: "shopDealsMeta",
    featured: "shopFeatured",
    featuredMeta: "shopFeaturedMeta",
    homeCategories: "shopHomeCategories",
    latestProducts: "shopLatestProducts",
    similarProducts: "shopSimilarProducts",
    addresses: "shopAddresses",
    siteSetting: "shopSiteSetting",
    page: "shopPage",
    faqs: "shopFaqs",
    blogs: "shopBlogs",
    blog: "shopBlog",
    teams: "shopTeams",
    testimonials: "shopTestimonials",
  },
  user: {
    cart: "userCart",
    wishlist: "userWishlist",
    orders: "userOrders",
    order: "userOrder",
    reviews: "userReviews",
    review: "userReview",
  },
  guestReview: "guestReview",
  influencer: {
    productLinks: "influencerProductLinks",
    linksEarning: "influencerLinksEarning",
    payments: "influencerPayments",
    payment: "influencerPayment",
  },
  business: {
    appointments: "appointments",
    products: {
      latest: "LatestProducts",
    },
    orders: "businessOrders",
    order: "businessOrder",
    distributors: {
      orders: "distributorOrders",
      order: "distributorOrder",
    },
    notifications: "businessNotifications",
  },
};

export default queryKeys;
