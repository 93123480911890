import { createBrowserRouter } from "react-router-dom";

import {
  // Public routes start
  Root,
  // RequireLayout,
  Layout,
  Home,
  Login,
  Register,
  RegisterAsInfluencer,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  Product,
  ProductsByCategory,
  ProductsByBrand,
  DealProducts,
  FeaturedProducts,
  SearchProducts,
  RecommendationProducts,
  LatestProducts,
  EsewaPaymentSuccess,
  EsewaPaymentFail,
  SuspenseLoading,
  About,
  Contact,
  Brands,
  Page,
  Faqs,
  Blogs,
  Blog,
  NotFound,
  ErrorFallback,
  // Public routes end

  // User routes start
  RequireUser,
  UserCartCheckout,
  UserWishlist,
  UserDashboard,
  UserProfile,
  UserAddressBook,
  UserRewardCoin,
  UserOrders,
  UserReviews,
  UserChangePassword,
  // User routes end

  // business routes start
  RequireBusiness,
  BusinessLogin,
  BusinessRegister,
  BusinessForgotPassword,
  BusinessResetPassword,
  BusinessModalChoose,
  BusinessBookAppointment,
  BusinessRetails,
  BusinessDashboard,
  BusinessProfile,
  BusinessChangePassword,
  BusinessOrderHistory,
  RequireDistributor,
  DistributorDashboard,
  DistributorProfile,
  DistributorOrder,
  DistributorOrderDetails,

  // business routes end

  // Influencer routes start
  RequireInfluencer,
  InfluencerProfile,
  InfluencerChangePassword,
  InfluencerDashboard,
  InfluencerProductLinks,
  InfluencerLinksEarning,
  InfluencerPayments,
  // Influencer routes end

  // Admin routes start
  RequireAdmin,
  AdminProfile,
  AdminChangePassword,
  AdminDashboard,
  AdminSliders,
  AdminCategories,
  AdminBrands,
  AdminColors,
  AdminDeliveryAddresses,
  AdminVouchers,
  AdminProducts,
  AdminReviews,
  AdminOrders,
  AdminInfluencersLinksEarning,
  AdminInfluencerLinksEarning,
  AdminInfluencerPayments,
  AdminSkinReports,
  AdminProductForm,
  AdminWholesaleOrders,
  AdminOrdersAsGuest,
  AdminWholesaleProducts,
  WholesaleProductForm,
  AdminWholesaleUsers,
  AdminWholesaleParlor,
  AdminWholesaleDistributor,
  AdminWholesaleAppointment,
  AdminWholesaleParlourVoucher,
  AdminWholesaleShopIncentive,
  AdminUsersUsers,
  AdminUsersInfluencers,
  AdminUsersInfluencerApplications,
  AdminUsersAdmins,
  AdminSiteSetting,
  AdminContactMessages,
  AdminHomepageAds,
  AdminEmailTemplates,
  AdminEmailTemplate,
  AdminSubscribers,
  AdminPages,
  AdminPage,
  AdminFaqs,
  AdminBlogs,
  AdminBlogForm,
  AdminCatalogue,
  AdminTeams,
  AdminTestimonials,
  // Admin routes end
} from "./imports";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "register/influencer",
            element: <RegisterAsInfluencer />,
          },
          {
            path: "reset-password/:token",
            element: <ResetPassword />,
          },
          {
            path: "verify-email/:token",
            element: <VerifyEmail />,
          },
          {
            path: "/business/register",
            element: <BusinessRegister />,
          },

          {
            path: "/business/login",
            element: <BusinessLogin />,
          },
          {
            path: "/business/forgot-password",
            element: <BusinessForgotPassword />,
          },
          {
            path: "/business/reset-password",
            element: <BusinessResetPassword />,
          },
          {
            path: "product/:slug",
            element: <Product />,
          },
          {
            path: "product/:slug/:refCode",
            element: <Product />,
          },
          {
            path: "category/:slug",
            element: <ProductsByCategory />,
          },
          {
            path: "brand/:slug",
            element: <ProductsByBrand />,
          },
          {
            path: "products/deals",
            element: <DealProducts />,
          },
          {
            path: "products/featured",
            element: <FeaturedProducts />,
          },
          {
            path: "products/search/:keyword",
            element: <SearchProducts />,
          },
          {
            path: "products/recommendation",
            element: <RecommendationProducts />,
          },
          {
            path: "products/latest",
            element: <LatestProducts />,
          },
          {
            path: "page/esewa-payment-success",
            element: <EsewaPaymentSuccess />,
          },
          {
            path: "page/esewa-payment-fail",
            element: <EsewaPaymentFail />,
          },
          {
            path: "suspense-loading",
            element: <SuspenseLoading />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "brands",
            element: <Brands />,
          },
          {
            path: "privacy-policy",
            element: <Page slug="privacy-policy" />,
          },
          {
            path: "terms-and-conditions",
            element: <Page slug="terms-and-conditions" />,
          },
          {
            path: "faq",
            element: <Faqs />,
          },
          {
            path: "blog",
            element: <Blogs />,
          },
          {
            path: "blog/:slug",
            element: <Blog />,
          },
        ],
      },
      {
        path: "user",
        element: <RequireUser />,
        children: [
          {
            path: "cart/checkout",
            element: <UserCartCheckout />,
          },
          {
            path: "wishlist",
            element: <UserWishlist />,
          },
          {
            path: "profile",
            children: [
              {
                path: "",
                element: <UserDashboard />,
              },
              {
                path: "information",
                element: <UserProfile />,
              },
              {
                path: "address",
                element: <UserAddressBook />,
              },
              {
                path: "reward-coin",
                element: <UserRewardCoin />,
              },
              {
                path: "orders",
                element: <UserOrders />,
              },
              {
                path: "reviews",
                element: <UserReviews />,
              },
              {
                path: "change-password",
                element: <UserChangePassword />,
              },
            ],
          },
        ],
      },
      {
        path: "influencer",
        element: <RequireInfluencer />,
        children: [
          {
            path: "",
            element: <InfluencerDashboard />,
          },
          {
            path: "profile",
            element: <InfluencerProfile />,
          },
          {
            path: "change-password",
            element: <InfluencerChangePassword />,
          },
          {
            path: "links",
            element: <InfluencerProductLinks />,
          },
          {
            path: "links-earning",
            element: <InfluencerLinksEarning />,
          },
          {
            path: "payments",
            element: <InfluencerPayments />,
          },
        ],
      },
      {
        path: "admin",
        element: <RequireAdmin />,
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
          {
            path: "profile",
            element: <AdminProfile />,
          },
          {
            path: "change-password",
            element: <AdminChangePassword />,
          },
          {
            path: "sliders",
            element: <AdminSliders />,
          },
          {
            path: "categories",
            element: <AdminCategories />,
          },
          {
            path: "brands",
            element: <AdminBrands />,
          },
          {
            path: "colors",
            element: <AdminColors />,
          },
          {
            path: "delivery-addresses",
            element: <AdminDeliveryAddresses />,
          },
          {
            path: "vouchers",
            element: <AdminVouchers />,
          },
          {
            path: "products",
            element: <AdminProducts />,
          },
          {
            path: "products/create",
            element: <AdminProductForm mode="create" />,
          },
          {
            path: "products/:id/edit",
            element: <AdminProductForm mode="edit" />,
          },
          {
            path: "reviews",
            element: <AdminReviews />,
          },
          {
            path: "orders",
            element: <AdminOrders />,
          },
          {
            path: "order-as-guest",
            element: <AdminOrdersAsGuest />,
          },
          {
            path: "influencers/links-earning",
            element: <AdminInfluencersLinksEarning />,
          },
          {
            path: "influencers/:id/links-earning",
            element: <AdminInfluencerLinksEarning />,
          },
          {
            path: "influencers/payments",
            element: <AdminInfluencerPayments />,
          },
          {
            path: "skin-report",
            element: <AdminSkinReports />,
          },
          {
            path: "business/orders",
            element: <AdminWholesaleOrders />,
          },
          {
            path: "business/products",
            element: <AdminWholesaleProducts />,
          },
          {
            path: "business/products/create",
            element: <WholesaleProductForm mode="create" />,
          },
          {
            path: "business/products/:id/edit",
            element: <WholesaleProductForm mode="edit" />,
          },
          {
            path: "business/users",
            element: <AdminWholesaleUsers />,
          },
          {
            path: "business/parlours",
            element: <AdminWholesaleParlor />,
          },
          {
            path: "business/distributors",
            element: <AdminWholesaleDistributor />,
          },
          {
            path: "business/appointment",
            element: <AdminWholesaleAppointment />,
          },
          {
            path: "business/parlour-voucher",
            element: <AdminWholesaleParlourVoucher />,
          },
          {
            path: "business/shop-incentive",
            element: <AdminWholesaleShopIncentive />,
          },
          {
            path: "users/users",
            element: <AdminUsersUsers />,
          },
          {
            path: "users/influencers",
            element: <AdminUsersInfluencers />,
          },
          {
            path: "users/influencer-applications",
            element: <AdminUsersInfluencerApplications />,
          },
          {
            path: "users/admins",
            element: <AdminUsersAdmins />,
          },
          {
            path: "misc/site-setting",
            element: <AdminSiteSetting />,
          },
          {
            path: "misc/contact-messages",
            element: <AdminContactMessages />,
          },
          {
            path: "misc/email-templates",
            element: <AdminEmailTemplates />,
          },
          {
            path: "misc/home-ads",
            element: <AdminHomepageAds />,
          },
          {
            path: "misc/email-templates/:slug",
            element: <AdminEmailTemplate />,
          },
          {
            path: "misc/subscribers",
            element: <AdminSubscribers />,
          },
          {
            path: "misc/pages",
            element: <AdminPages />,
          },
          {
            path: "misc/pages/:slug",
            element: <AdminPage />,
          },
          {
            path: "misc/faqs",
            element: <AdminFaqs />,
          },
          {
            path: "blogs",
            element: <AdminBlogs />,
          },
          {
            path: "blogs/create",
            element: <AdminBlogForm mode="create" />,
          },
          {
            path: "blogs/:id/edit",
            element: <AdminBlogForm mode="edit" />,
          },
          {
            path: "catalogue",
            element: <AdminCatalogue />,
          },
          {
            path: "about/teams",
            element: <AdminTeams />,
          },
          {
            path: "about/testimonials",
            element: <AdminTestimonials />,
          },
        ],
      },
      {
        path: "business",
        element: <RequireBusiness />,
        children: [
          {
            path: "",
            element: <BusinessModalChoose />,
          },
          {
            path: "appointment",
            element: <BusinessBookAppointment />,
          },
          {
            path: "retails",
            element: <BusinessRetails />,
          },
          {
            path: "profile",
            element: <BusinessDashboard />,
            children: [
              {
                path: "",
                element: <BusinessProfile />,
              },

              {
                path: "change-password",
                element: <BusinessChangePassword />,
              },

              {
                path: "orders",
                element: <BusinessOrderHistory />,
              },
            ],
          },
        ],
      },
      {
        path: "distributor",
        element: <RequireDistributor />,
        children: [
          {
            path: "",
            element: <DistributorDashboard />,
            children: [
              {
                path: "",
                element: <DistributorOrder />,
              },
              {
                path: ":id",
                element: <DistributorOrderDetails />,
              },
              {
                path: "change-password",
                element: <BusinessChangePassword />,
              },
              {
                path: "profile",
                element: <DistributorProfile />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
