import * as React from "react";

export const Root = React.lazy(() => import("components/root/Root"));
export const RequireLayout = React.lazy(
  () => import("router/requireLayout/RequireLayout")
);
export const Layout = React.lazy(() => import("components/layout/Layout"));
export const Home = React.lazy(() => import("components/home/Home"));
export const Login = React.lazy(() => import("components/auth/login/Login"));
export const Register = React.lazy(
  () => import("components/auth/register/Register")
);

// business
export const RequireBusiness = React.lazy(
  () => import("router/requireBusiness/RequireBusiness")
);
export const BusinessLogin = React.lazy(
  () => import("components/auth/business/login/BusinessLogin")
);
export const BusinessRegister = React.lazy(
  () => import("components/auth/business/register/BusinessRegister")
);
export const BusinessForgotPassword = React.lazy(
  () => import("components/auth/business/forgetPassword/BusinessForgetPassword")
);
export const BusinessResetPassword = React.lazy(
  () =>
    import(
      "components/auth/business/forgetPassword/resetPassword/BusinessResetPassword"
    )
);
export const BusinessPanVerification = React.lazy(
  () =>
    import("components/auth/business/register/PanVerification/PanVerfication")
);
export const BusinessModalChoose = React.lazy(
  () => import("components/business/choose/BusinessModalChoose")
);
export const BusinessBookAppointment = React.lazy(
  () => import("components/business/appointment/BusinessAppointment")
);
export const BusinessRetails = React.lazy(
  () => import("components/business/retails/BusinessRetails")
);
export const BusinessDashboard = React.lazy(
  () =>
    import(
      "components/layout/businessLayout/businessDashboard/BusinessDashboard"
    )
);
export const BusinessProfile = React.lazy(
  () => import("components/business/dashboard/profile/Profile")
);
export const BusinessChangePassword = React.lazy(
  () => import("components/business/dashboard/changePassword/ChangePassword")
);
export const BusinessOrderHistory = React.lazy(
  () => import("components/business/dashboard/orderHistory/OrderHistory")
);
export const RequireDistributor = React.lazy(
  () => import("router/requireDistributor/RequireDistributor")
);
export const DistributorDashboard = React.lazy(
  () =>
    import(
      "components/layout/businessLayout/distributorDashboard/DistributorDashboard"
    )
);
export const DistributorProfile = React.lazy(
  () => import("components/distributor/dashboard/profile/Profile")
);
export const DistributorOrder = React.lazy(
  () => import("components/distributor/dashboard/order/OrderHistory")
);
export const DistributorOrderDetails = React.lazy(
  () => import("components/distributor/dashboard/order/DistributorOrderDetails")
);

//
export const RegisterAsInfluencer = React.lazy(
  () => import("components/auth/registerAsInfluencer/RegisterAsInfluencer")
);
export const ForgotPassword = React.lazy(
  () => import("components/auth/forgotPassword/ForgotPassword")
);
export const ResetPassword = React.lazy(
  () => import("components/auth/resetPassword/ResetPassword")
);
export const VerifyEmail = React.lazy(
  () => import("components/auth/verifyEmail/VerifyEmail")
);
export const Product = React.lazy(
  () => import("components/shop/product/Product")
);
export const ProductsByCategory = React.lazy(
  () => import("components/shop/productsByCategory/ProductsByCategory")
);
export const ProductsByBrand = React.lazy(
  () => import("components/shop/productsByBrand/ProductsByBrand")
);
export const DealProducts = React.lazy(
  () => import("components/shop/dealProducts/DealProducts")
);
export const FeaturedProducts = React.lazy(
  () => import("components/shop/featuredProducts/FeaturedProducts")
);
export const SearchProducts = React.lazy(
  () => import("components/shop/searchProducts/SearchProducts")
);
export const RecommendationProducts = React.lazy(
  () => import("components/shop/recommendationProducts/RecommendationProducts")
);
export const LatestProducts = React.lazy(
  () => import("components/shop/latestProducts/LatestProducts")
);
export const EsewaPaymentSuccess = React.lazy(
  () => import("components/pages/EsewaPaymentSuccess")
);
export const EsewaPaymentFail = React.lazy(
  () => import("components/pages/EsewaPaymentFail")
);
export const SuspenseLoading = React.lazy(
  () => import("components/loading/suspenseLoading/SuspenseLoading")
);
export const About = React.lazy(() => import("components/pages/about/About"));
export const Contact = React.lazy(
  () => import("components/pages/contact/Contact")
);
export const Brands = React.lazy(() => import("components/shop/brands/Brands"));
export const Page = React.lazy(() => import("components/misc/page/Page"));
export const Faqs = React.lazy(() => import("components/misc/faq/Faq"));
export const Blogs = React.lazy(() => import("components/misc/blogs/Blogs"));
export const Blog = React.lazy(() => import("components/misc/blogs/Blog"));
export const NotFound = React.lazy(
  () => import("components/pages/notFound/NotFound")
);
export const ErrorFallback = React.lazy(
  () => import("components/errorFallback/ErrorFallback")
);

// User routes start
export const RequireUser = React.lazy(
  () => import("router/requireUser/RequireUser")
);
export const UserCartCheckout = React.lazy(
  () => import("components/user/cart/CartCheckout")
);
export const UserWishlist = React.lazy(
  () => import("components/user/wishlist/Wishlist")
);
export const UserDashboard = React.lazy(
  () => import("components/user/dashboard/Dashboard")
);
export const UserProfile = React.lazy(
  () => import("components/user/dashboard/profile/Profile")
);
export const UserAddressBook = React.lazy(
  () => import("components/user/dashboard/addressBook/AddressBook")
);
export const UserRewardCoin = React.lazy(
  () => import("components/user/dashboard/rewardCoin/RewardCoin")
);
export const UserOrders = React.lazy(
  () => import("components/user/dashboard/orders/Orders")
);
export const UserReviews = React.lazy(
  () => import("components/user/dashboard/reviews/Reviews")
);
export const UserChangePassword = React.lazy(
  () => import("components/user/dashboard/changePassword/ChangePassword")
);
// User routes end

// Influencer routes start
export const RequireInfluencer = React.lazy(
  () => import("router/requireInfluencer/RequireInfluencer")
);
export const InfluencerProfile = React.lazy(
  () => import("components/influencer/profile/Profile")
);
export const InfluencerChangePassword = React.lazy(
  () => import("components/influencer/changePassword/ChangePassword")
);
export const InfluencerDashboard = React.lazy(
  () => import("components/influencer/dashboard/Dashboard")
);
export const InfluencerProductLinks = React.lazy(
  () => import("components/influencer/productLinks/ProductLinks")
);
export const InfluencerLinksEarning = React.lazy(
  () => import("components/influencer/linksEarning/LinksEarning")
);
export const InfluencerPayments = React.lazy(
  () => import("components/influencer/payments/Payments")
);
// Influencer routes end

// Admin routes start
export const RequireAdmin = React.lazy(
  () => import("router/requireAdmin/RequireAdmin")
);
export const AdminProfile = React.lazy(
  () => import("components/admin/profile/Profile")
);
export const AdminChangePassword = React.lazy(
  () => import("components/admin/changePassword/ChangePassword")
);
export const AdminDashboard = React.lazy(
  () => import("components/admin/dashboard/Dashboard")
);
export const AdminSliders = React.lazy(
  () => import("components/admin/sliders/Sliders")
);
export const AdminCategories = React.lazy(
  () => import("components/admin/categories/Categories")
);
export const AdminBrands = React.lazy(
  () => import("components/admin/brands/Brands")
);
export const AdminColors = React.lazy(
  () => import("components/admin/colors/Colors")
);
export const AdminDeliveryAddresses = React.lazy(
  () => import("components/admin/deliveryAddresses/DeliveryAddresses")
);
export const AdminVouchers = React.lazy(
  () => import("components/admin/vouchers/Vouchers")
);
export const AdminProducts = React.lazy(
  () => import("components/admin/products/Products")
);
export const AdminReviews = React.lazy(
  () => import("components/admin/reviews/Reviews")
);
export const AdminOrders = React.lazy(
  () => import("components/admin/orders/Orders")
);
export const AdminOrdersAsGuest = React.lazy(
  () => import("components/admin/OrdersAsGuest/OrderAsGuest")
);
export const AdminInfluencersLinksEarning = React.lazy(
  () => import("components/admin/influencerLinks/InfluencersLinksEarnings")
);
export const AdminInfluencerLinksEarning = React.lazy(
  () => import("components/admin/influencerLinks/InfluencerLinksEarnings")
);
export const AdminInfluencerPayments = React.lazy(
  () => import("components/admin/influencerPayments/InfluencerPayments")
);
export const AdminSkinReports = React.lazy(
  () => import("components/admin/skinReports/SkinReports")
);
export const AdminProductForm = React.lazy(
  () => import("components/admin/products/form/ProductForm")
);
export const AdminWholesaleOrders = React.lazy(
  () => import("components/admin/wholesale/orders/Orders")
);

export const AdminWholesaleProducts = React.lazy(
  () => import("components/admin/wholesale/products/Products")
);
export const WholesaleProductForm = React.lazy(
  () => import("components/admin/wholesale/products/form/WholesaleProductForm")
);
export const AdminWholesaleUsers = React.lazy(
  () => import("components/admin/wholesale/shop/Users")
);
export const AdminWholesaleParlor = React.lazy(
  () => import("components/admin/wholesale/parlor/Parlor")
);
export const AdminWholesaleDistributor = React.lazy(
  () => import("components/admin/wholesale/distributor/Distributor")
);
export const AdminWholesaleAppointment = React.lazy(
  () => import("components/admin/wholesale/appointment/Appointment")
);
export const AdminWholesaleParlourVoucher = React.lazy(
  () => import("components/admin/wholesale/parlourVoucher/ParlourVoucher")
);
export const AdminWholesaleShopIncentive = React.lazy(
  () => import("components/admin/wholesale/shopIncentive/ShopIncentive")
);
export const AdminUsersUsers = React.lazy(
  () => import("components/admin/users/users/Users")
);
export const AdminUsersInfluencers = React.lazy(
  () => import("components/admin/users/influencers/Influencers")
);
export const AdminUsersInfluencerApplications = React.lazy(
  () => import("components/admin/users/influencers/InfluencerApplications")
);
export const AdminUsersAdmins = React.lazy(
  () => import("components/admin/users/admins/Admins")
);
export const AdminSiteSetting = React.lazy(
  () => import("components/admin/misc/siteSetting/SiteSetting")
);
export const AdminContactMessages = React.lazy(
  () => import("components/admin/misc/contactMessages/ContactMessages")
);
export const AdminHomepageAds = React.lazy(
  () => import("components/admin/misc/homeAds/HomeAds")
);
export const AdminEmailTemplates = React.lazy(
  () => import("components/admin/misc/emailTemplates/EmailTemplates")
);
export const AdminEmailTemplate = React.lazy(
  () => import("components/admin/misc/emailTemplates/form/EmailTemplateForm")
);
export const AdminSubscribers = React.lazy(
  () => import("components/admin/misc/subscribers/Subscribers")
);
export const AdminPages = React.lazy(
  () => import("components/admin/misc/pages/Pages")
);
export const AdminPage = React.lazy(
  () => import("components/admin/misc/pages/form/PageForm")
);
export const AdminFaqs = React.lazy(
  () => import("components/admin/misc/faqs/Faqs")
);
export const AdminBlogs = React.lazy(
  () => import("components/admin/blogs/Blogs")
);
export const AdminBlogForm = React.lazy(
  () => import("components/admin/blogs/form/BlogForm")
);
export const AdminCatalogue = React.lazy(
  () => import("components/admin/catalogue/Catalogue")
);
export const AdminTeams = React.lazy(
  () => import("components/admin/aboutUs/teams/Teams")
);
export const AdminTestimonials = React.lazy(
  () => import("components/admin/aboutUs/testimonials/Testimonials")
);
// Admin routes end
