import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

let firebaseApp: any;
let messaging: any;
let analytics: any;

try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
  analytics = getAnalytics(firebaseApp); // Initialize Firebase Analytics
} catch (error) {
  console.log("Failed to initialize firebase messaging:\n", error);
}

const getCurrentToken = async () => {
  const currentToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  return currentToken;
};

export const getMessagingToken = async () => {
  try {
    let currentToken = await getCurrentToken();

    if (currentToken) {
      console.log("Messaging token:\n", currentToken);
      return currentToken;
    } else {
      console.log(
        "No messaging token available. Request permission to generate one."
      );

      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        currentToken = await getCurrentToken();

        if (currentToken) {
          console.log("Messaging token:\n", currentToken);
          return currentToken;
        }
      }
    }
  } catch (error) {
    console.log("Error while retrieving token:\n", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { firebaseApp, messaging, analytics }; // Export the necessary variables if needed
